import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import CartProducts from 'components/Cart/CartProducts';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import CartSidebar from 'components/Cart/CartSidebar';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import InbankRentalProducts from 'components/Cart/InbankRental/InbankRentalProducts';
import InbankRentalSidebar, {
    IInbankRentalConfig,
    IInbankRentalLabels,
} from 'components/Cart/InbankRental/InbankRentalSidebar';
import { CartType } from 'components/Cart/Enum/CartType';
import { empty } from '../../helpers/empty';

export interface ICheckoutCartProps {
    config: {
        products: ICartProduct[];
        cartTotals: ICartTotals;
        cartContainer?: string;
        emptyMessage?: string;
        inbankRentalEmptyMessage?: string;
        inbankRentalProductLabels?: IInbankRentalProductLabels;
        inbankRentalTotals?: ICartTotals;
        inbankRentalProducts?: ICartProduct[];
        inbankRentalConfig?: IInbankRentalConfig;
        inbankRentalLabels?: IInbankRentalLabels;
        productRemovedLabel: string;
    };
}
interface CartResponseType {
    products: ICartProduct[];
    cartTotals: ICartTotals;
    inbankRentalTotals?: ICartTotals;
    inbankRentalProducts?: ICartProduct[];
    inbankRentalConfig?: IInbankRentalConfig;
}

export interface IInbankRentalProductLabels {
    mainProductLabel: string;
    secondaryProductLabel: string;
}

const CheckoutCart = (props: ICheckoutCartProps) => {
    let {
        products,
        cartTotals,
        cartContainer,
        emptyMessage,
        inbankRentalEmptyMessage,
        inbankRentalProducts,
        inbankRentalTotals,
        inbankRentalConfig,
        inbankRentalProductLabels,
        inbankRentalLabels,
        productRemovedLabel,
    } = props.config;
    const [state, setState] = useState({
        products,
        cartTotals,
        inbankRentalProducts,
        inbankRentalTotals,
        inbankRentalConfig,
    });
    const [activeBasket, setActiveBasket] = useState<string>((): string => {
        return window.sessionStorage.getItem('cartFlow') ?? CartType.REGULAR_CART;
    });
    const [{}, cartRequest] = useMutation(() => request({ type: 'cart', url: 'cart/cart/index', notApi: true }));

    const effect = useCallback(async () => {
        const response = await cartRequest();

        const cartResponse: CartResponseType = response.body;

        if (empty(cartResponse.inbankRentalConfig) && activeBasket === CartType.INBANK_RENTAL_CART) {
            window.dispatchEvent(
                new CustomEvent('tab-cart-select-change', { detail: { cartSelection: CartType.REGULAR_CART } }),
            );
        } else if (empty(cartResponse.products)) {
            window.dispatchEvent(
                new CustomEvent('tab-cart-select-change', { detail: { cartSelection: CartType.INBANK_RENTAL_CART } }),
            );
        }

        setState({
            products: cartResponse.products,
            cartTotals: cartResponse.cartTotals,
            inbankRentalTotals: cartResponse?.inbankRentalTotals,
            inbankRentalProducts: cartResponse.inbankRentalProducts,
            inbankRentalConfig: cartResponse.inbankRentalConfig,
        });
        window.dispatchEvent(
            new CustomEvent('tab-inbank-rental-monthly-payment-change', { detail: cartResponse.inbankRentalConfig }),
        );
        window.dispatchEvent(new CustomEvent('tab-cart-total-change', { detail: cartResponse.cartTotals }));
    }, [cartRequest]);

    useEffect(() => {
        window.dispatchEvent(
            new CustomEvent('tab-inbank_rental-monthly-payment-change', { detail: inbankRentalConfig }),
        );
        window.dispatchEvent(new CustomEvent('tab-cart-total-change', { detail: cartTotals }));
    }, []);

    const activeBasketEffect = (detail) => {
        setActiveBasket(detail.detail);
    };

    /**
     * TODO rewrite to Store
     * @param props
     * @constructor
     */
    useEffect(() => {
        window.addEventListener('active-basket-changed', activeBasketEffect);
        window.addEventListener('cart-altered', effect);
        window.addEventListener('hasInsuranceChanged', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
            window.removeEventListener('hasInsuranceChanged', effect);
        };
    }, []);

    return (
        <React.Fragment>
            <CartSidebar
                activeBasket={activeBasket}
                emptyMessage={emptyMessage}
                cart={state.cartTotals}
                cartContainer={cartContainer}
                tabActive={
                    activeBasket === CartType.REGULAR_CART ||
                    activeBasket === CartType.SLICE3_CART ||
                    activeBasket === CartType.ESTO_SLICE3_CART
                }
            />
            {state.inbankRentalConfig && !empty(state.inbankRentalConfig) && state.inbankRentalTotals && (
                <InbankRentalSidebar
                    emptyMessage={inbankRentalEmptyMessage}
                    cart={state.inbankRentalTotals}
                    tabActive={activeBasket === CartType.INBANK_RENTAL_CART}
                    inbankRentalConfig={state.inbankRentalConfig}
                />
            )}
            <CartProducts
                setActiveBasket={setActiveBasket}
                emptyMessage={emptyMessage}
                products={state.products}
                quoteId={state.cartTotals?.quoteId || ''}
                tabActive={
                    activeBasket === CartType.REGULAR_CART ||
                    activeBasket === CartType.SLICE3_CART ||
                    activeBasket === CartType.ESTO_SLICE3_CART
                }
                productRemovedLabel={productRemovedLabel}
            />
            {state.inbankRentalConfig && !empty(state.inbankRentalConfig) && state.inbankRentalProducts && (
                <InbankRentalProducts
                    inbankRentalProductLabels={inbankRentalProductLabels}
                    emptyMessage={inbankRentalEmptyMessage}
                    products={state.inbankRentalProducts}
                    quoteId={state.inbankRentalTotals?.quoteId || ''}
                    tabActive={activeBasket === CartType.INBANK_RENTAL_CART}
                    inbankRentalLabels={inbankRentalLabels}
                    productRemovedLabel={productRemovedLabel}
                />
            )}
        </React.Fragment>
    );
};

export default CheckoutCart;
