import * as React from 'react';
import { ICartProduct, IRequiredProduct } from 'components/Cart/Interfaces/ICartProduct';

export interface IProps {
    requiredProduct: IRequiredProduct;
    mainProduct: ICartProduct;
}

const RequiredProduct = (props: IProps) => {
    const { requiredProduct, mainProduct } = props;

    return (
        <React.Fragment>
            <tr className="cart-table-subproduct">
                <td className="cart-table-image">
                    <span className={'image'}>
                        <img src={requiredProduct.image} alt={requiredProduct.name} />
                    </span>
                </td>
                <td>
                    <p className="cart-table-title">
                        {requiredProduct.pageUrl ? (
                            <a href={requiredProduct.pageUrl}>{requiredProduct.name}</a>
                        ) : (
                            `${requiredProduct.name}`
                        )}
                    </p>
                    <ul className="cart-table-config">
                        <li key={`${requiredProduct.id}-${mainProduct.id}`}>
                            <label>
                                <input
                                    type="radio"
                                    name={`required-${mainProduct.id}`}
                                    disabled={true}
                                    checked={true}
                                />
                                <span>
                                    {requiredProduct.pageUrl ? (
                                        <a href={requiredProduct.pageUrl}>
                                            {requiredProduct.name} (+{requiredProduct.priceTotal})
                                        </a>
                                    ) : (
                                        `${requiredProduct.name} (+${requiredProduct.priceTotal})`
                                    )}
                                </span>
                            </label>
                        </li>
                    </ul>
                </td>
                <td>
                    <ul className="cart-table-price">
                        <li>
                            <span className="label">{mainProduct.price.label}</span>{' '}
                            <span className="value sum">{requiredProduct.price}</span>
                        </li>
                        <li>
                            <span className="label">{mainProduct.qty.label}</span>{' '}
                            <span className="value">{requiredProduct.qty}</span>
                        </li>
                        <li className="total" aria-live="polite">
                            <span className="label">{mainProduct.total.label}</span>{' '}
                            <span className="value sum">{requiredProduct.priceTotal}</span>
                        </li>
                    </ul>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default RequiredProduct;
