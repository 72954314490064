import * as React from 'react';
import { useMutation } from 'redux-query-react';
import { useContext, useState } from 'react';
import Menu from 'vkid-ui/lib/Components/Menu';
import MenuItem from 'vkid-ui/lib/Components/MenuItem';
import { IAddress, ICustomer } from '../../../interfaces/checkout/customer/ICustomer';
import LayoutSplit from 'vkid-ui/lib/Layouts/LayoutSplit';
import LayoutSplitColumn from 'vkid-ui/lib/Layouts/LayoutSplitColumn';
import ExistingAddresses from '../../../components/address/existingAddresses';
import LayoutForm from 'vkid-ui/lib/Layouts/LayoutForm';
import FormRow from 'vkid-ui/lib/Components/FormRow';
import { useTranslation } from 'react-i18next';
import { postRequest } from 'data/requests/postRequest';
import { useEffect } from 'react';
import { IPaymentMethodResponse } from '../../../interfaces/payment/IPaymentMethodResponse';
import HandleMethod, { PaymentMethodEnum } from '../../../components/checkout/Payment/Methods/MethodHandler';
import { ResponseStatusEnum } from '../../../enums/ResponseStatus';
import RedirectAbsolute, { IRedirectProps } from '../../../components/Redirect/RedirectAbsolute';
import SelectedAddressToPostAddress from '../../../components/address/selectedAddressToPostAddress';
import Toaster from 'vkid-ui/lib/Components/Toaster';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import ExistingAddressForm from '../../../components/checkout/Address/ExistingAddressForm';
import NewAddressOverlay from '../../../components/address/NewAddressOverlay';
import BusinessAddressForm from '../../../components/checkout/Address/BusinessAddressForm';
import { empty } from '../../../helpers/empty';
import { IPaymentMethodExtraData } from '../../../interfaces/payment/IPaymentMethodExtraData';
import { useRef } from 'react';
import Banklinks from '../../../components/checkout/Payment/Banklinks';
import { MutableRefObject } from 'react';
import { ICart } from '../../../interfaces/checkout/cart/cartType';
import Agreements from '../../../components/checkout/Payment/Agreements';
import LoadingOverlay from '../../../components/checkout/Overlay/LoadingOverlay';
import useOverlays from '../../../components/overlay/Overlay';
import Subscription from '../../../components/checkout/Payment/Subscription';
import { IShippingMethod } from '../../../interfaces/checkout/shipping/IShippingMethod';
import PersonalIdForm from '../../../components/checkout/Payment/PersonalIdForm';
import { CartContext } from '../../../components/checkout/Content';
import { IShippingAddress } from '../../../interfaces/checkout/address/IShippingInformationData';
import WideButtons from 'components/Checkout/components/checkout/Payment/WideButtons';
import useSlice3Cart from 'data/requests/product/slice3/hooks/useSlice3Cart';
import { CartType } from 'components/Cart/Enum/CartType';
import RadioList from 'vkid-ui/lib/Applications/Checkout/Components/RadioList';
import RadioWithLabel from 'vkid-ui/lib/Components/RadioWithLabel';

export interface IPaymentOnClickInput {
    paymentMethodExtraData?: IPaymentMethodExtraData;
    redirect?: IRedirectProps;
    rawMethodData?: any;
}

export interface IPaymentMethodProps {
    method: IPaymentMethodResponse;
    clientType: CLIENT_TYPE;
    paymentMethodExtraData: MutableRefObject<IPaymentMethodExtraData | undefined> | undefined;
    onClick: (input: IPaymentOnClickInput) => void;
    cart: ICart | undefined;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
    shippingMethod: IShippingMethod | null;
    selectedAddress: IShippingAddress | null;
}

interface IProps {
    customer: ICustomer | null;
    paymentMethods: IPaymentMethodResponse[] | null;
    email: string | undefined;
    cart: ICart | undefined;
    selectedMethod: IShippingMethod | null;
    selectedAddress: IShippingAddress | null;
}

export interface ITerms {
    id: string;
    accepted: boolean;
}

export enum CLIENT_TYPE {
    CLIENT_BUSINESS = 'business',
    CLIENT_REGULAR = 'client',
}

const PaymentBlock = (props: IProps) => {
    const { t } = useTranslation();
    const { openOverlay, closeOverlay } = useOverlays();
    const { customer, paymentMethods, email, cart } = props;
    const allowQuery = useRef(false);
    const [updateState, setUpdateState] = useState(false);
    const [renderPersonalCode, setRenderPersonalCode] = useState<boolean>(!!window.isPersonalCodeEnabled);
    const [personalCodeCallback, setPersonalCodeCallback] = useState<{ callbackMethod: () => void } | undefined>(
        undefined,
    );
    const [personalIdSet, setPersonalIdSet] = useState(false);
    const cartContext = useContext(CartContext);
    const [redirectUrl, setRedirectUrl] = useState<IRedirectProps | undefined>();
    const [methodCode, setMethodCode] = useState<string>();
    const [methodName, setMethodName] = useState<string>();
    const [selectedAddress, selectAddress] = useState<IAddress | null | undefined>();
    const [errors, setErrors] = useState(false);
    const [clientType, setClientType] = useState(CLIENT_TYPE.CLIENT_REGULAR);
    const [terms, setTerms] = useState<ITerms[]>(
        window.agreements?.totalRecords > 0
            ? window.agreements.items.map((item) => ({
                  id: item.agreement_id,
                  accepted: false,
              }))
            : [],
    );
    const [newAddress, selectNewAddress] = useState<IAddress | null | undefined>();
    const paymentMethodRenderers: React.Component<any, any>[] = [];
    const rawData = useRef();
    const paymentMethodExtraData = useRef<Partial<IPaymentMethodExtraData>>();
    const { isSlice3Cart } = useSlice3Cart();
    const isEstoSlice3Cart = window.sessionStorage.getItem('cartFlow') === CartType.ESTO_SLICE3_CART;

    const addresses = ExistingAddresses(true, selectedAddress, selectAddress, null, customer, null).filter(
        (address) => address.isBusiness,
    );

    const [{ isFinished, status }, postData] = useMutation((data, url) =>
        postRequest({ type: 'response', url, data, useStoreCode: true }),
    );
    const getExtensionAttributes = () => {
        return {
            agreement_ids: terms.filter((item) => item.accepted).map((item) => item.id),
        };
    };
    const setAllowQuery = (state: boolean) => {
        allowQuery.current = state;
        setUpdateState(!updateState);
    };
    const isInbankRentalPayment = window.isInbankRental;

    if (!paymentMethodRenderers.length) {
        const isFreePaymentMethod = paymentMethods?.find((method) => method.code === PaymentMethodEnum.free);

        let MethodComponent;

        // free payment method is returned only if grandTotal is <=0, if presents show only free payment method
        if (isFreePaymentMethod) {
            const onClick = (input: IPaymentOnClickInput) => {
                rawData.current = input.rawMethodData;
                setMethodCode(isFreePaymentMethod.code);
                paymentMethodExtraData.current = input.paymentMethodExtraData;
                setRedirectUrl(input.redirect);
                window.dispatchEvent(new CustomEvent('business-address-save'));
                window.dispatchEvent(new CustomEvent('subscription-save'));
                if (clientType !== CLIENT_TYPE.CLIENT_BUSINESS || (selectedAddress && !selectedAddress.isNew)) {
                    setAllowQuery(true);
                }
            };
            MethodComponent = HandleMethod({
                method: isFreePaymentMethod,
                clientType,
                paymentMethodExtraData,
                onClick,
                cart,
                email,
                extensionAttributes: getExtensionAttributes,
                setAllowQuery,
                shippingMethod: props.selectedMethod,
                selectedAddress: props.selectedAddress,
            });
            paymentMethodRenderers.push(MethodComponent);
        }

        const queryParams = new URLSearchParams(window.location.search);
        const isInbankRentalQuery = queryParams.has('inbankRental');
        const isSlice3Query = queryParams.has('slice3');
        const isEstoSlice3Query = queryParams.has('esto_slice3');

        paymentMethods?.forEach((method: IPaymentMethodResponse, index, array) => {
            if (isFreePaymentMethod) {
                return;
            }

            if (
                (isInbankRentalQuery && method.code !== PaymentMethodEnum.inbank_rental) ||
                (isSlice3Query && method.code !== PaymentMethodEnum.inbank_payments_ee_slice) ||
                (isEstoSlice3Query && method.code !== PaymentMethodEnum.esto_x)
            ) {
                return;
            }

            const onClick = (input: IPaymentOnClickInput) => {
                // ensure personalCode is rendered through custom payment component, callback is defined
                // this will allow for method not to validate personal code if it is not needed
                // this will not affect window.isPersonalCodeEnabled behavior
                if (!!personalCodeCallback) {
                    setRenderPersonalCode(false);
                }
                rawData.current = input.rawMethodData;
                setMethodCode(method.code);
                setMethodName(input?.rawMethodData?.name);
                paymentMethodExtraData.current = input.paymentMethodExtraData;
                setRedirectUrl(input.redirect);
                window.dispatchEvent(new CustomEvent('business-address-save'));
                window.dispatchEvent(new CustomEvent('subscription-save'));

                if (clientType !== CLIENT_TYPE.CLIENT_BUSINESS || (selectedAddress && !selectedAddress.isNew)) {
                    setAllowQuery(true);
                }
            };

            // In case of inbank rental only inbank rental payment will be shown, otherwise exclude inbank rental
            if (
                (!isInbankRentalPayment && PaymentMethodEnum.inbank_rental !== method.code) ||
                (isInbankRentalPayment && PaymentMethodEnum.inbank_rental === method.code)
            ) {
                MethodComponent = HandleMethod({
                    method,
                    clientType,
                    paymentMethodExtraData,
                    onClick,
                    cart,
                    email,
                    extensionAttributes: getExtensionAttributes,
                    setAllowQuery,
                    shippingMethod: props.selectedMethod,
                    selectedAddress: props.selectedAddress,
                });
            }

            if (MethodComponent && !paymentMethodRenderers.includes(MethodComponent)) {
                paymentMethodRenderers.push(MethodComponent);
            }
        });
    }

    const queryAction = () => {
        if (allowQuery.current) {
            let hasErrors = !!terms.find((term) => !term.accepted);

            if (renderPersonalCode && clientType !== CLIENT_TYPE.CLIENT_BUSINESS && !personalIdSet) {
                hasErrors = true;
            }

            setErrors(hasErrors);

            if (!hasErrors && methodCode) {
                openOverlay('payment-loading-overlay', true);
                setAllowQuery(false);
                let address;
                if (selectedAddress && clientType === CLIENT_TYPE.CLIENT_BUSINESS) {
                    address = SelectedAddressToPostAddress(selectedAddress);
                }

                const addressInformation = {
                    ...paymentMethodExtraData.current?.extraParams,
                    email,
                    billing_address: typeof address !== 'undefined' ? address : undefined,
                    paymentMethod: {
                        method: methodCode,
                        extension_attributes: getExtensionAttributes(),
                        additional_data: { ...paymentMethodExtraData.current?.additionalData },
                    },
                };

                window.dispatchEvent(
                    new CustomEvent('checkout-step-proceed', {
                        detail: {
                            action: 'add-payment-info',
                            method: { methodCode, methodName },
                            items: cartContext.cartItems,
                            customer: cartContext.customer,
                        },
                    }),
                );

                const url =
                    paymentMethodExtraData.current?.paymentVerificationUrl ||
                    (isLoggedIn
                        ? 'carts/mine/payment-information'
                        : `guest-carts/${window.quoteIdMask}/payment-information`);
                postData(
                    {
                        ...addressInformation,
                    },
                    url,
                ).then((response) => {
                    const responseSuccessful = response && response.status === ResponseStatusEnum.ok;
                    if (responseSuccessful) {
                        window.dispatchEvent(
                            new CustomEvent('payment-response-receive', {
                                detail: {
                                    methodCode,
                                    paymentResponse: response.body,
                                    rawData: rawData.current,
                                    setRedirectUrl,
                                    customer,
                                },
                            }),
                        );
                    }

                    if (!responseSuccessful && response?.body?.message) {
                        closeOverlay('payment-loading-overlay', false);
                        setMethodCode(undefined);
                        setAllowQuery(true);

                        Toaster.addToast({
                            intent: 'danger',
                            text: response.body.message,
                            asHtml: true,
                        });
                    }
                    if (response.body && response.body.code === 455) {
                        setRedirectUrl({ to: `checkout/cart` });
                    }
                });
            } else {
                setMethodCode(undefined);
            }
        }
    };
    useEffect(queryAction);

    useEffect(() => {
        if (selectedAddress && clientType === CLIENT_TYPE.CLIENT_BUSINESS) {
            const address: IShippingAddress = SelectedAddressToPostAddress(selectedAddress);
            window.dispatchEvent(
                new CustomEvent('business-address-forward', {
                    detail: { address },
                }),
            );
        }
    }, [selectedAddress, clientType]);

    useEffect(() => {
        if (isFinished && redirectUrl && !empty(redirectUrl.to)) {
            if (status === 400) {
                setTimeout(() => RedirectAbsolute({ to: `checkout` }), 2000);
            } else {
                RedirectAbsolute(redirectUrl);
            }
        }
        if (selectedAddress && selectedAddress.isNew) {
            selectNewAddress(selectedAddress);
        }
    });
    const addressSaveAction = () => {
        setMethodCode(undefined);
        setRedirectUrl(undefined);
        window.dispatchEvent(new CustomEvent('business-address-save'));
    };

    const newAddressForm = (
        <BusinessAddressForm
            shippingAddress={props.selectedAddress}
            proceedAction={() => setAllowQuery(true)}
            selectedAddress={newAddress}
            customer={customer}
            selectAddress={selectAddress}
        />
    );

    const effectPersonalCodeNeeded = (e) => {
        if (e.detail && e.detail.callbackMethod) {
            setPersonalCodeCallback(e.detail);
            setRenderPersonalCode(true);
        }
    };

    useEffect(() => {
        window.addEventListener('personalCodeNeeded', effectPersonalCodeNeeded);
        return function cleanup() {
            window.removeEventListener('personalCodeNeeded', effectPersonalCodeNeeded);
        };
    }, []);

    return (
        <LayoutSplit>
            {window.isBusinessEnabled && !isInbankRentalPayment && !isSlice3Cart && !isEstoSlice3Cart ? (
                <LayoutSplitColumn>
                    <RadioList>
                        <RadioWithLabel
                            label={t('checkout.Private customer')}
                            checked={clientType === CLIENT_TYPE.CLIENT_REGULAR}
                            onChange={() => setClientType(CLIENT_TYPE.CLIENT_REGULAR)}
                            id={CLIENT_TYPE.CLIENT_REGULAR}
                        />
                        <RadioWithLabel
                            label={t('checkout.Business customer')}
                            checked={clientType === CLIENT_TYPE.CLIENT_BUSINESS}
                            onChange={() => setClientType(CLIENT_TYPE.CLIENT_BUSINESS)}
                            id={CLIENT_TYPE.CLIENT_BUSINESS}
                        />
                    </RadioList>
                    {clientType === CLIENT_TYPE.CLIENT_BUSINESS && (
                        <React.Fragment>
                            {!isLoggedIn && (
                                <p>
                                    {t('checkout.To use invoice payment method')}{' '}
                                    <a href={window.LOGIN_URL}>{t('checkout.please sign in')}</a>
                                </p>
                            )}
                            {customer &&
                            addresses &&
                            ((addresses.length === 1 && !addresses.find((address) => address.isNew)) ||
                                addresses.length > 1) ? (
                                <React.Fragment>
                                    <ExistingAddressForm
                                        proceedAction={() => setAllowQuery(true)}
                                        selectedAddress={selectedAddress}
                                        addresses={addresses}
                                        showButton={false}
                                        title={t(
                                            'checkout.Please select the company address and hit the Update button',
                                        )}
                                    />
                                    <NewAddressOverlay
                                        title={t('checkout.Add a business address')}
                                        newAddressForm={newAddressForm}
                                        proceedAction={() => addressSaveAction()}
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>{newAddressForm}</React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </LayoutSplitColumn>
            ) : (
                <LayoutSplitColumn />
            )}
            <LayoutSplitColumn>
                {renderPersonalCode &&
                    empty(window.isPersonalCodeBottomLocated) &&
                    clientType !== CLIENT_TYPE.CLIENT_BUSINESS && (
                        <PersonalIdForm
                            selectedAddress={props.selectedAddress}
                            setPersonalIdSet={setPersonalIdSet}
                            errors={errors}
                            personalCodeCallback={personalCodeCallback}
                        />
                    )}
                <Agreements terms={terms} setTerms={setTerms} errors={errors} />
                {!!window.isSubscriptionEnabled && <Subscription customer={customer} email={email} />}
                <LayoutForm layout="vertical">
                    <FormRow
                        label={<b>{t('checkout.Payment Method:')}</b>}
                        description={t('checkout.You will be redirected back to us after payment')}
                    >
                        {isInbankRentalPayment || isSlice3Cart || isEstoSlice3Cart ? (
                            <WideButtons components={paymentMethodRenderers} />
                        ) : (
                            <Banklinks components={paymentMethodRenderers} />
                        )}
                    </FormRow>
                </LayoutForm>
                {renderPersonalCode &&
                    !empty(window.isPersonalCodeBottomLocated) &&
                    clientType !== CLIENT_TYPE.CLIENT_BUSINESS && (
                        <PersonalIdForm
                            selectedAddress={props.selectedAddress}
                            setPersonalIdSet={setPersonalIdSet}
                            errors={errors}
                            personalCodeCallback={personalCodeCallback}
                        />
                    )}
            </LayoutSplitColumn>
            <LoadingOverlay index={'payment-loading-overlay'} />
        </LayoutSplit>
    );
};

export default PaymentBlock;
