import * as React from 'react';
import { useEffect, useState } from 'react';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import Product from 'components/Cart/Product/Product';
import classNames from 'classnames';
import { CartType } from 'components/Cart/Enum/CartType';
import Callout from 'vkid-ui/lib/Components/Callout';

export interface ICartProductsProps {
    products: ICartProduct[];
    quoteId: string;
    emptyMessage?: string;
    tabActive: boolean;
    setActiveBasket: (value: string) => void;
    productRemovedLabel: string;
}

const CartProducts = (props: ICartProductsProps) => {
    const { products, quoteId, emptyMessage, tabActive, setActiveBasket, productRemovedLabel } = props;
    const [error, setError] = useState<string>();
    const [message, setMessage] = useState<string>();

    if (!products || !quoteId) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <div className={classNames('layout-cart__main', { hidden: !tabActive })}>
                {error && (
                    <Callout intent="danger" isAlert ariaLive="assertive">
                        {error}
                    </Callout>
                )}

                {message && (
                    <Callout intent="success" isAlert ariaLive="assertive">
                        {message}
                    </Callout>
                )}

                {emptyMessage && <div className="cart-callout">{emptyMessage}</div>}
                <table className={'cart-table'}>
                    <tbody>
                        {products.map((product, key) => (
                            <Product
                                setActiveBasket={setActiveBasket}
                                product={product}
                                key={key}
                                quoteId={quoteId}
                                cartType={CartType.REGULAR_CART}
                                setError={setError}
                                setMessage={setMessage}
                                isInbankRental={false}
                                productRemovedLabel={productRemovedLabel}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default CartProducts;
