import * as React from 'react';
import { useCallback, useState } from 'react';
import { ICartProduct, IRequiredProduct } from 'components/Cart/Interfaces/ICartProduct';
import { useMutation } from 'redux-query-react';
import { postRequest } from 'data/requests/postRequest';
import { extractErrors } from '../../../helpers/request/extractErrors';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import RelatedProduct from 'components/Cart/Product/RelatedProduct';
import RequiredProduct from 'components/Cart/Product/RequiredProduct';
import { CartType } from 'components/Cart/Enum/CartType';
import { IInbankRentalProductLabels } from 'components/Cart/CheckoutCart';
import { empty } from '../../../helpers/empty';
import InsuranceSwitcher from 'components/InbankRental/InsuranceSwitcher';
import { IInbankRentalLabels } from '../InbankRental/InbankRentalSidebar';
import useAuth from '../../../hooks/useAuth';

export interface ICartProductProps {
    quoteId: string;
    product: ICartProduct;
    isInbankRental: boolean;
    setError: (message: string) => void;
    setMessage: (message: string) => void;
    cartType: CartType;
    inbankRentalProductLabels?: IInbankRentalProductLabels;
    setActiveBasket?: (value: string) => void;
    inbankRentalLabels?: IInbankRentalLabels;
    productRemovedLabel: string;
}

const Product = (props: ICartProductProps) => {
    const {
        product,
        quoteId,
        cartType,
        setError,
        setMessage,
        isInbankRental,
        inbankRentalProductLabels,
        setActiveBasket,
        inbankRentalLabels,
        productRemovedLabel,
    } = props;
    const [{}, cartRequest] = useMutation((data, id, method) =>
        postRequest({
            method: method,
            type: 'response',
            url: getRequestUrl(id),
            data,
        }),
    );

    const getRequestUrl = (id) => {
        if (cartType === CartType.INBANK_RENTAL_CART) {
            return isLoggedIn()
                ? `inbank-rental-carts/mine/items/${id}`
                : `inbank-rental-guest-carts/${quoteId}/items/${id}`;
        }

        return isLoggedIn() ? `carts/mine/items/${id}` : `guest-carts/${quoteId}/items/${id}`;
    };

    const { customer } = useAuth();
    const [loading, setIsLoading] = useState(false);
    const alterProduct = useCallback(
        async (id: any, sku: string, qty: number) => {
            if (loading) {
                return;
            }
            setIsLoading(true);
            const data: any = {
                cartItem: {
                    sku,
                    qty,
                    quote_id: quoteId,
                },
            };
            const response = await cartRequest(data, id, 'PUT' as any);
            setIsLoading(false);
            if (response.status === 200) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
            } else {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    setError(errors.error);
                }
            }
        },
        [cartRequest],
    );

    const removeProduct = useCallback(
        async (id: any) => {
            if (loading) {
                return;
            }
            setIsLoading(true);
            const response = await cartRequest({} as any, id, 'DELETE' as any);
            setIsLoading(false);
            if (response.status === 200) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
                dispatchRemoveFromCartGtmEvent();
                setMessage(productRemovedLabel);
            } else {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    setError(errors.error);
                }
            }
        },
        [cartRequest],
    );

    const dispatchAddToCartGtmEvent = () =>
        window.dispatchEvent(
            new CustomEvent('cart-altered', {
                detail: {
                    action: 'add-to-cart',
                    sku: product.sku,
                    name: product.name,
                    qty: product.qty.value,
                    priceValue: product.price.value,
                    itemListId: '',
                    itemListName: 'Cart View',
                },
            }),
        );

    const dispatchRemoveFromCartGtmEvent = () =>
        window.dispatchEvent(
            new CustomEvent('gtm_product_event', {
                detail: {
                    event_name: 'remove_from_cart',
                    user_id: customer?.id,
                    name: product.name,
                    id: product.sku,
                    fullprice: product.oldPrice ?? product.price.value,
                    discount: product.oldPrice ? product.price.value : 0,
                    brand: product.brand,
                    category: product.category,
                },
            }),
        );

    return (
        <React.Fragment>
            <tr>
                <td className={'cart-table-image'}>
                    <a href={product.url}>
                        <span className={'image'}>
                            <img src={product.image} alt={product.name} />
                        </span>
                    </a>
                </td>
                <td>
                    <p className={'cart-table-title'}>
                        <a href={product.url}>{product.name}</a>
                    </p>
                    {/*{isInbankRental && inbankRentalProductLabels && (
                        <p className={'cart-table-meta'}>
                            {!empty(product.isInbankRental)
                                ? inbankRentalProductLabels.mainProductLabel
                                : inbankRentalProductLabels.secondaryProductLabel}
                        </p>
                    )}*/}
                    {product.callout && <p className="cart-callout">{product.callout}</p>}
                    {product.options && (
                        <ul className="cart-table-specs">
                            {product.options.map((option) => (
                                <li key={option.label}>
                                    <span className="label">{option.label}</span>{' '}
                                    <span className="value">{option.value}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                    {inbankRentalLabels?.useInsuranceLabel && !!product.isInbankRental && (
                        <InsuranceSwitcher
                            config={{
                                extraInfo: inbankRentalLabels?.extraInfo,
                                useInsuranceLabel: inbankRentalLabels?.useInsuranceLabel,
                            }}
                        />
                    )}
                </td>
                <td>
                    <ul className={'cart-table-price'}>
                        <li>
                            <span className="label">{product.price.label}</span>
                            <span className="value sum">{product.price.value}</span>
                        </li>
                        <li>
                            <span className={'label'}>{product.qty.label}</span>
                            <span className={loading ? 'value loading' : 'value'}>
                                {!product.related?.length && (
                                    <a
                                        href={'#'}
                                        className={'value-button'}
                                        onClick={() => {
                                            alterProduct(product.id, product.sku, parseInt(product.qty.value) - 1);
                                            dispatchRemoveFromCartGtmEvent();
                                        }}
                                    >
                                        -
                                    </a>
                                )}
                                <span className={'number'}>{product.qty.value}</span>
                                {!product.related?.length && (
                                    <a
                                        href={'#'}
                                        className={'value-button'}
                                        onClick={() => {
                                            alterProduct(product.id, product.sku, parseInt(product.qty.value) + 1);
                                            dispatchAddToCartGtmEvent();
                                        }}
                                    >
                                        +
                                    </a>
                                )}
                            </span>
                        </li>
                        <li className={'total'} aria-live="polite">
                            <span className="label">{product.total.label}</span>
                            <span className="value sum">{product.total.value}</span>
                        </li>
                    </ul>
                    {!(isInbankRental && product.isInbankRental) && product.removeUrl && (
                        <p className={'cart-table-remove'}>
                            <a href={'#'} onClick={() => removeProduct(product.id)}>
                                {product.removeUrl.label}
                            </a>
                        </p>
                    )}
                </td>
            </tr>
            {!isInbankRental &&
                product.related &&
                product.related.map((relatedProduct) => (
                    <RelatedProduct
                        setActiveBasket={setActiveBasket}
                        key={relatedProduct.id}
                        relatedProduct={relatedProduct}
                        mainProduct={product}
                        loading={loading}
                        isInbankRental={isInbankRental}
                        setIsLoading={setIsLoading}
                        setError={setError}
                        setMessage={setMessage}
                    />
                ))}
            {product.requiredItems &&
                product.requiredItems.map((requiredProduct: IRequiredProduct) => (
                    <RequiredProduct key={requiredProduct.id} mainProduct={product} requiredProduct={requiredProduct} />
                ))}
        </React.Fragment>
    );
};

export default Product;
